<!-- 用户编辑弹窗 -->
<template>
  <a-modal :width="900" :visible="visible" :confirm-loading="loading" :title="isUpdate ? '修改奖项' : '新建奖项'"
    :body-style="{ paddingBottom: '8px' }" @update:visible="updateVisible" @ok="save">
    <a-form ref="form" :model="form" :rules="rules" :labelCol="{ span: 2, offset: 0 }"
      :wrapper-col="{ span: 22, offset: 0 }">
      <a-row :gutter="16">
        <a-col :md="24" :sm="24" :xs="24">
          <a-form-item label="会场" name="sceneId" v-if="!sceneId">
            <a-select v-model:value="form.sceneId" show-search placeholder="选择一个会场" showArrow
              :filterOption="filterOption" @change="change" allow-clear>
              <a-select-option v-for="(item, index) in sceneList" :key="index" :value="item.value">
                {{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="奖项名称" name="luckName">
            <a-input v-model:value="form.luckName" placeholder="请输入奖项名称" allow-clear />
          </a-form-item>
          <a-form-item label="抽奖人" name="luckMaker">
            <a-input v-model:value="form.luckMaker" placeholder="请输入抽奖人" allow-clear />
          </a-form-item>
          <a-form-item label="奖项数量" name="luckCount">
            <a-input-number v-model:value="form.luckCount" placeholder="请输入奖项数量" style="width: 100%" allow-clear />
          </a-form-item>
          <a-form-item label="排序" name="sortNumber">
            <a-input-number v-model:value="form.sortNumber" placeholder="请输入排序" style="width: 100%" allow-clear />
          </a-form-item>
          <a-form-item label="奖品名称" name="goodsName">
            <a-input v-model:value="form.goodsName" placeholder="请输入奖品名称" style="width: 100%" allow-clear />
          </a-form-item>
          <a-form-item label="奖品图片" name="goodsPic">
            <a-upload list-type="picture-card" v-model:file-list="goodsPicList" @preview="handlePreview" :customRequest="
              ({ file }) => uploadFile(file, 'goodsPic')
            " :remove="removeFile">
              <div v-if="goodsPicList.length < 1">
                <plus-outlined />
                <div class="ant-upload-text">上传</div>
              </div>
            </a-upload>
            <a-modal :visible="previewVisible" :footer="null" :bodyStyle="{ paddingTop: '50px' }"
              @cancel="previewVisible = false">
              <img alt="example" style="width: 100%" :src="previewImage" />
            </a-modal>
          </a-form-item>
          <a-form-item label="展示大屏:" name="screenId">
            <a-select v-model:value="form.screenIdArr" show-search placeholder="选择展示大屏" showArrow
              :filterOption="filterOption" :token-separators="[',']" mode="multiple">
              <a-select-option v-for="(item, index) in screenList" :key="index" :value="item.value">
                {{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </a-modal>
</template>

<script>
import * as screenApi from '@/api/scene/screen.js'
import * as luckApi from '@/api/scene/luck'
import setting from '@/config/setting'
export default {
  name: 'sceneEdit',
  emits: ['done', 'update:visible'],
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object,
    sceneId: Object
  },
  data() {
    return {
      // 表单数据
      form: Object.assign({}, this.data),
      // 表单验证规则
      rules: {
        activityId: [
          {
            required: true,
            type: 'string',
            trigger: 'change'
          }
        ]
      },
      // 提交状态
      loading: false,
      // 上传地址
      uploadUrl: setting.uploadUrl,
      // 是否是修改
      isUpdate: false,
      // 活动列表
      activityList: [],
      // 管理人员
      managerList: [],
      goodsPicList: [],
      previewVisible: false
    }
  },
  watch: {
    data() {
      if (this.data) {
        this.isUpdate = true
        this.form = this.data
        if (this.form.screenId) {
          this.form.screenIdArr = this.strToArr(this.form.screenId)
        } else {
          this.form.screenIdArr = []
        }
        if (this.form.goodsPic) {
          this.goodsPicList[0] = {
            uid: '-10',
            name: 'image.png',
            status: 'done',
            url: this.form.goodsPic
          }
        }
      } else {
        this.form = {}
        this.screenIdArr = []
        this.isUpdate = false
      }
      if (this.$refs.form) {
        this.$refs.form.clearValidate()
      }
    }
  },
  mounted() {
    console.log(this.form)
    this.queryScreenList(this.sceneId)
  },
  methods: {
    /* 保存编辑 */
    save() {
      this.$refs.form
        .validate()
        .then(() => {
          this.loading = true
          if (this.form.screenIdArr && this.form.screenIdArr.length > 0) {
            this.form.screenId = this.arrToStr(this.form.screenIdArr)
          } else {
            this.form.screenId = ''
          }
          this.form.sceneId = this.sceneId
          if (this.goodsPicList && this.goodsPicList.length > 0) {
            this.form.goodsPic = this.goodsPicList[0].url
          }
          luckApi.save(this.form, this.isUpdate)
            .then((res) => {
              this.loading = false
              if (res.code === 0) {
                this.$message.success(res.msg)
                if (!this.isUpdate) {
                  this.form = {}
                }
                this.updateVisible(false)
                this.$emit('done')
              } else {
                this.$message.error(res.msg)
              }
            })
            .catch((e) => {
              this.loading = false
              this.$message.error(e.message)
            })
        })
        .catch(() => { })
    },
    // 数组转字符串方法
    arrToStr(arr) {
      if (arr && arr.length > 0) {
        const str = arr.join(',')
        return str
      }
    },
    // 字符串转数组方法
    strToArr(str) {
      if (str) {
        return str.split(',')
      }
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value)
    },
    /* 选择过滤查询 */
    filterOption(input, option) {
      return (
        option.children[0].children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
    queryScreenList(sceneId) {
      screenApi.all({ sceneId: sceneId }).then(res => {
        if (res.code === 0) {
          this.screenList = res.data.map(item => {
            const newItem = {}
            newItem.value = item.screenId + ''
            newItem.label = item.tags + '(' + item.pageUrl + ')'
            return newItem
          })
        }
      }).catch(() => {

      })
    },
    handlePreview(pointPhoto) {
      this.previewImage = pointPhoto.url || pointPhoto.preview
      this.previewVisible = true
    },
    handleCancel() {
      this.previewVisible = false
    },
    removeFile(file) {
      this.goodsPicList = []
    },
    uploadFile(file, name) {
      const formData = new FormData()
      formData.append('file', file)
      const uid = file.uid
      const hide = this.$message.loading('上传中..', 0)
      this.$http
        .post(this.uploadUrl, formData)
        .then((res) => {
          hide()
          if (res.data.code === 0) {
            this.goodsPicList = [{
              uid: uid,
              name: 'image.png',
              status: 'done',
              url: res.data.location
            }]
          } else {
            this.$message.error(res.data.msg)
          }
        })
        .catch((e) => {
          hide()
          this.$message.error(e.message)
        })
      return false
    }
  }
}
</script>

<style scoped>
</style>
